<template>
    <el-table :data="cashData" stripe tooltip-effect="dark" fit>
        <el-table-column label="旅行社">
            <template slot-scope="scope">{{ scope.row.TravelAgency_Name }}</template>
        </el-table-column>
        <el-table-column label="剩余金额">
            <template slot-scope="scope">{{ scope.row.TravelAgency_RestMoney }}</template>
        </el-table-column>
        <el-table-column label="总汇">
            <template slot-scope="scope">{{ scope.row.TravelAgency_TotalMoney }}</template>
        </el-table-column>
        <el-table-column label="提现金额">
            <template slot-scope="scope">{{ scope.row.TravelAgency_CashMoney }}</template>
        </el-table-column>
        <!-- <el-table-column label="发起时间" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.Cash_Create }}</template>
        </el-table-column> -->

    </el-table>
</template>
<script>
export default{
    data(){
        return {
            cashData:[]
        }
    },
    mounted(){
        this.getCashData()
    },
    methods:{
        getCashData() {
            this.ipost(
                "/AdminTravel/CashTravel.ashx?type=statisticstravelarea", null,
                (res) => {
                    console.log("cashData res:", res);
                    this.cashData = res.list;
                }
            );
        },
    }
}

</script>